import React, { Component } from 'react';
import { Calendar } from "react-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ReactTable from 'react-table';
import { Modal, FormLabel, FormControl, Table } from 'react-bootstrap';
import { Container, FormGroup, Col, Row } from 'reactstrap';
import moment from 'moment';
import { request } from '../../constants/constants';
import { Multiselect } from 'multiselect-react-dropdown';
import Pagination from '../../components/Pagination/pagination';
import { Link } from 'react-router-dom';
import Loader from "react-loader-spinner";

class SecretaryPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            currentDoctorId: "",
            currentHospitalId: "",
            currentSlot: "",
            currentBookingId: "",
            pickedDate: "",
            dayAddNumberCollection: [],
            doctors: [], 
            bookings: [],
            hospitals: [],
            booking_date_collection: [],
            patient_result: [],
            procedures: [],
            probes: [],
            patients: [],
            selectedPatients: [],
            staffs: [],
            hospitalsForMoveBooking: [],
            booking_dates: [],
            offListDate: [],
            showAddBooking: false,
            showPatientsModal: false,
            updatePatientTreatment: false,
            isLoading: true,
            showError: false,
            showErrorForExistingPatient: false,
            showDeleteModal: false,
            isLoadingGetSummaryPatients: false,
            showMoveBooking: false,
            showDeleteBookingModal: false,
            staff_id: '',
            start_date: '',
            end_date: '',
            calendarData:'booking-cycle',
            notes_id: '',
            viewCalendarNotes: 'block',
            showEditCalendarNotes: 'none',
            saveButtonDisplay: 'none',
            updateButtonDisplay: 'block',
            date: '',

            week_start_dates: [],
            week_end_dates: [],
            doctorsWithBookingCycles: [],
            slotOptions: [],
            start_dates: [],

        }
    }

    componentDidMount(){

        this.init();

        request('GET', '/procedures/allProceduresOnlyActive', {}, true).then(result => {
            // console.log(result)
            const procedures = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.procedure_type,
            }));
            this.setState({ procedures });
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

        request('GET', '/patients/', {}, true).then(result => {
            // console.log(result);
            const patients = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.title + '. ' + obj.first_name + ' ' + (obj.second_name ? obj.second_name + ' ' : '') + obj.last_name,
                // hospitals: obj.hospitals
            }));
            this.setState({ patients });
        });

        request('GET', '/users/staff', {}, true).then(result => {
            // console.log(result)
            if (result.data.data !== undefined) {
                const staffs = result.data.data.map(obj => ({
                    staffId: obj.id,
                    staff_name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name
                }));
                
                this.setState({ staffs });
            }
        });

        request('GET', '/doctors/', {}, true).then(result => {
            const doctors = result.data.data.map(obj => ({
                id: obj.ID,
                name: obj.title + '. ' + obj.first_name + ' ' + obj.last_name,
                hospitals: obj.hospitals
            }));
            this.setState({ doctors }); 
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
        });

        request('GET', '/doctors/secretary/allDoctorsByBookingCycleSec', {}, true).then(result => {
            console.log("doctors", result)
            const doctorsWithBookingCycles = result.data.data.map(obj => ({
                id: obj.doctor.id,
                name: obj.doctor.title + '. ' + obj.doctor.first_name + ' ' + obj.doctor.last_name
            }));
            doctorsWithBookingCycles.sort((a, b) => a.name > b.name ? 1 : -1);
            this.setState({ doctorsWithBookingCycles })
        })
      
    }

    init(){
        this.getBookings().then(result => {
            const bookings = result.data.data;
            this.setState({
                bookingsByDate: bookings,
                isLoading: false
            });
        });
    }

    getAllDoctorsByBookingCycle = () => {

        request('GET', '/doctors/secretary/allDoctorsByBookingCycleSec', {}, true).then(result => {
          // console.log("doctors", result)
          const doctorsWithBookingCycles = result.data.data.map(obj => ({
            cycleId: obj.id,
            id: obj.doctor.id,
            doctorId: obj.doctor_id,
            name: obj.doctor ? obj.doctor.title + '. ' + obj.doctor.first_name + ' ' + obj.doctor.last_name : null,
            last_name: obj.doctor.last_name,
            procedures: obj.booking_cycle_procedures ? obj.booking_cycle_procedures : obj.booking_cycle_offlist_procedures,
            hospitalName: obj.hospital.name,
            hospitalId: obj.hospital_id,
            slot: obj.slot,
            week: obj.week,
            day: obj.day,
            date: obj.date,
            column_start_date: obj.start_date,
            column_end_date: obj.end_date
          }));
          doctorsWithBookingCycles.sort((a, b) => a.last_name > b.last_name ? 1 : -1);
          this.setState({ doctorsWithBookingCycles })
        })
    
      }


    getBookings(){
        return request('GET', '/bookings/', {}, true);
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    hideAddBooking = () => {
        this.setState({ 
            showAddBooking: false,
            showMoveBooking: false
        })
    }

    getHospitalHeight(hospital) {
        // console.log(hospital, 'hospitalCount')
        let height = 0; 
        height = height + (hospital.items.length * 50);
        // console.log(height, 'height')

        return height;
    }

    getDoctorHeight(doctor) {
        // console.log(doctor, 'doctor')
        let height = 0; 
        doctor.items.forEach(hospital => {
            height  = height + this.getHospitalHeight(hospital);
        })
        // console.log(height, 'height')
        return height;
    }

    getSlotHeight(slot) {
        // console.log(slot, 'slot')
        let height = 0; 
        slot.forEach(dcotor => {
            height  = height + this.getDoctorHeight(dcotor);
        })
        // console.log(height, 'height')
        return height;
    }

    showActions = (rowIndex,master_key,doctorIndex,hospitalIndex,procedureIndex) => e => {

        const bookingsByDate = this.state.bookingsByDate;
        const bookingDate = bookingsByDate[rowIndex];
        const procedure = bookingDate.items[master_key][doctorIndex].items[hospitalIndex].items[procedureIndex];
        console.log('procedure', rowIndex,master_key,doctorIndex,hospitalIndex,procedureIndex)
        console.log('procedure',bookingDate)
        console.log('procedure',procedure)

        if(procedure.showActions === undefined) {
            procedure.showActions = true;
        } else {
            procedure.showActions = !procedure.showActions;
        }
        this.setState({
            bookingsByDate
        })
    }

    handleShowPatientsModal(booking_date, booking_doctor, booking_hospital, booking_slot){
        this.setState({
            showPatientsModal: true,
            add_staff_date: booking_date,
            add_staff_doctor_id: booking_doctor,
            add_staff_hospital_id: booking_hospital,
            add_staff_slot: booking_slot,
            // add_staff_procedure: booking_procedure,
            finalProcedure: []
        }, () => {
            this.showBookingCycles();
            this.getProceduresForBooking();
            this.getPatients(this.state.add_staff_doctor_id);

        })

        this.getSummaryList(booking_date, booking_doctor, booking_hospital, booking_slot)
    }

    getPatients = (docId) => {

        request('GET', `/patients/getBy/doctor/${docId}`, {}, true).then(result => {
            // console.log('result', result)
            const patients = result.data.data.patients.map(obj => ({
                id: obj.id,
                name: obj.title + '. ' + obj.first_name + ' ' + (obj.second_name ? obj.second_name + ' ' : '') + obj.last_name,
                last_name: obj.last_name,
                patient_procedure_id: obj.procedure
            }));
            patients.sort((a, b) => a.last_name > b.last_name ? 1 : -1);
            this.setState({ patients }); 
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })    
    
    }

    getSummaryList = (date, doctor_id, hospital_id, slot) => {
        this.setState({
            isLoadingGetSummaryPatients: true
        })

        request('GET', `/bookings/CalendarEvents/summaryList?booking_date=${date}&booking_doctor=${doctor_id}&booking_hospital=${hospital_id}&booking_slot=${slot}`, {}, true).then(res => {
            res.data.data.map(val => {
                this.setState({
                    doctorName: val.doctors.title + '. ' + val.doctors.first_name + ' ' + val.doctors.last_name,
                    hospitalName: val.hospitals.name,
                    procedureName: val.procedure,
                    slot: val.slot,
                    booking_date: moment(val.date).format("MMMM D YYYY"),
                    notes: val.notes,
                    booking_id: val.ID
                })
            })
            this.setState({
                patient_result: res.data.data,
                isLoadingGetSummaryPatients: false
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoadingGetSummaryPatients: false,
                error: err.response.data,
                responseCode: err.response.status,
                errorData: err.response.data.errors,
            })
        })
    }

    showBookingCycles = () => {
        //Get the booking cycle for calendars
        request('GET', `/booking_cycles/get/getByHospDocSlot/${this.state.add_staff_hospital_id}/${this.state.add_staff_doctor_id}/${this.state.add_staff_slot}`, {}, true).then(result => {
            // console.log("BookingCycle", result)
            if (result.data.data.length > 0) {
                const bookingCycles = result.data.data.map(obj => ({
                    cycleId: obj.id,
                    doctorId: obj.doctor.id,
                    procedures: obj.procedures ? obj.procedures : obj.booking_cycle_offlist_procedures,
                    doctorName: obj.doctor ? obj.doctor.title + '. ' + obj.doctor.first_name + ' ' + obj.doctor.last_name : null,
                    hospitalName: obj.hospital.name,
                    hospitalId: obj.hospital.id,
                    slot: obj.slot,
                    week: obj.week,
                    day: obj.day,
                    date: obj.date
                }));

                bookingCycles.sort((a, b) => a.doctorName > b.doctorName ? 1 : -1);

                let loopChecker = true;

                this.setState({ bookingCycles }, () => {

                    const booking_data = this.state.bookingCycles.map(obj => {
                        this.setState({
                            currentDay: obj.day,
                            hospitalName: obj.hospitalName,
                            hospitalId: obj.hospitalId,
                            doctorName: obj.doctorName,
                            doctorId: obj.doctorId,
                            slot: obj.slot
                        }, () => {
                            if (loopChecker) {
                                this.getOfficialDate(result.data.start_dates, result.data.booking_info, this.state.hospitalName, this.state.hospitalId, this.state.doctorName, this.state.doctorId, this.state.slot);
                                loopChecker = false;
                            }
                        })
                    })
                })
            } else {
                this.setState({ booking_date_collection_length: 0 })
            }
        });
    }

    getProceduresForBooking = () => {
        request(
          "GET",
          `/booking_cycles/fetch/forProcedures/${this.state.add_staff_hospital_id}/${this.state.add_staff_doctor_id}/${this.state.add_staff_slot}/${this.state.add_staff_date}`,
          {},
          true
        ).then((result) => {
          let getAllProcedures = [];
          let proceduresForBooking = [];
    
          const bookingCycles = result.data.data.map((obj) => {
              if(obj.booking_cycle_procedures && obj.booking_cycle_procedures.length > 0) {
                let bc_procedure = obj.booking_cycle_procedures;
                bc_procedure.map(val => {
                  getAllProcedures.push(val)
                })
              }
    
              if(obj.booking_cycle_offlist_procedures && obj.booking_cycle_offlist_procedures.length > 0){
                let oc_procedure = obj.booking_cycle_offlist_procedures;
                oc_procedure.map(val => {
                  getAllProcedures.push(val)
                })
              }
          });
    
          proceduresForBooking = getAllProcedures.map((val) => ({
            id: val.procedure_id,
            name: val.procedure_name,
          }));
    
          // console.log("getProceduresForBooking", proceduresForBooking)
          // console.log("result", result)
    
          this.setState({
            finalProcedure: proceduresForBooking,
          });
    
        });
      };

    getOfficialDate = (dates, booking_info, hospitalName, hospitalId, doctorName, doctorId, slot) => {
        const dateNow = new Date().getTime();
        let validDateContainer = [];

        const official_dates = dates.map(val => {
            let dateTimestamp = this.getDateTimeStamp(val);

            if (dateNow <= dateTimestamp) {
                this.state.booking_date_collection.push({ dates: moment(dateTimestamp).format('dddd, DD-MMMM-YYYY'), hospital: hospitalName, hospital_id: hospitalId, doctor: doctorName, doctor_id: doctorId, slot: slot });
                validDateContainer.push(val)
            }
        });

        for (let index = 0; index < booking_info.dates.length; index++) {
            let checkBookingDateTimeStamp = this.getDateTimeStamp(booking_info.dates[index]);
            if (dateNow <= checkBookingDateTimeStamp) {
                let dateIndex = validDateContainer.findIndex(obj => obj === booking_info.dates[index]);
                // console.log('dateIndex', dateIndex, booking_info.dates[index])
                if (dateIndex != -1) {
                    console.log(booking_info.procedures[index])
                    this.state.booking_date_collection[dateIndex]["procedure_name"] = booking_info.counted_procedures[index];
                    this.state.booking_date_collection.push();
                }
            }
        }

        this.setState({
            booking_date_collection_length: this.state.booking_date_collection.length
        })
    }

    getDateTimeStamp = (element) => {
        const startDate = new Date(element).getTime();
        return startDate;
    }

    handleShowBooking = (date) => {
        this.setState({
            show: true,
            showPatientsModal: false,
            booking_date: date
        })
        // this.getProceduresForBooking();
    }

    goToEditPatientBooking = (patient_id, procedure, patient_name) => {
        this.setState({ 
            showPatientsModal: false , 
            notes: "",
            procedure: "", 
            ur_number: "",
            probe: "",
            official_booking_id: "",
            editBookingPatientName: patient_name
         });
    
        request(  
          "GET",
          `/booking_cycles/fetch/forProcedures/${this.state.add_staff_hospital_id}/${this.state.add_staff_doctor_id}/${this.state.add_staff_slot}/${this.state.add_staff_date}`,
          {},
          true
        ).then((result) => {
    
          let getAllProcedures = [];
          let proceduresForBooking= [];
    
          const bookingCycles = result.data.data.map((obj) => {
              if(obj.booking_cycle_procedures && obj.booking_cycle_procedures.length > 0) {
                let bc_procedure = obj.booking_cycle_procedures;
                bc_procedure.map(val => {
                  getAllProcedures.push(val)
                })
              }
    
              if(obj.booking_cycle_offlist_procedures && obj.booking_cycle_offlist_procedures.length > 0){
                let oc_procedure = obj.booking_cycle_offlist_procedures;
                oc_procedure.map(val => {
                  getAllProcedures.push(val)
                })
              }
          });
    
          proceduresForBooking = getAllProcedures.map((val) => ({
            id: val.procedure_id,
            name: val.procedure_name,
          }));
    
          // console.log('goToEditPatientBooking', proceduresForBooking)
          // console.log("result", result)
    
          this.setState({
            procedures: proceduresForBooking,
          });
    
        });
    
        this.showBookingCycles();
    
        request("GET", `/probes/getOnlyActive`, {}, true).then((result) => {
          // console.log(result);
          const probes = result.data.data.map((obj) => ({
            id: obj.ID,
            name: obj.reference,
          }));
          this.setState({ probes });
        });
    
        request(
          "GET",
          `/bookings/getGeneralInfo/getBookingId?booking_date=${this.state.add_staff_date}&booking_doctor=${this.state.add_staff_doctor_id}&booking_hospital=${this.state.add_staff_hospital_id}&booking_slot=${this.state.add_staff_slot}&booking_patient=${patient_id}&booking_procedure=${procedure}`,
          {},
          true
        )
          .then((res) => {
            // console.log("res_booking", res);
            this.setState({
              showUpdateTreatment: true,
              official_booking_id: res.data.data.id,
              procedure: res.data.data.procedure_id,
              ur_number: res.data.data.ur_number,
              probe: res.data.data.probe,
              notes: res.data.data.notes,
              chkboxTreated: res.data.data.treated,
              chkboxCancelled: res.data.data.booking_status ? false : true,
            });
          })
          .catch((err) => {
            console.error(err.response);
            this.setState({
              isLoading: false,
              error: err.response.data,
              responseCode: err.response.status,
              errorData: err.response.data.errors,
            });
          });
    }

    goToMovePatient = (booking_id, patient_id, patient_name) => {

        this.setState({
          currentBookingId: booking_id,
          moveBookingPatientId: patient_id,
          moveBookingPatientName: patient_name,
          showMoveBooking: true,
          isLoadingForMovingPatient: false,
          isLoadingCalendar: false,
        })
        this.getAllDoctorsByBookingCycle();
      }

    handleFormSubmit = e => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        const data = {
            doctor_id: this.state.add_staff_doctor_id ? this.state.add_staff_doctor_id : this.state.doctorId,
            hospital_id: this.state.add_staff_hospital_id ? this.state.add_staff_hospital_id : this.state.hospitalId,
            procedure: this.state.selectedProcedures,
            patient_id: this.state.selectedPatients,
            slot: this.state.add_staff_slot ? this.state.add_staff_slot : this.state.slot,
            booking_status: 1,
            treated: 0,
            notes: this.state.notes,
            date: moment(this.state.booking_date).format('YYYY-MM-DD'),
            // patient_id: this.state.selectedPatient ? this.state.selectedPatient : this.state.selectedPatients,

        }
        // console.log(data)
        request('POST', '/bookings/', data, true).then(res => {
            // console.log(res)
            this.setState({
                isLoading: false,
                showSuccessModal: true,
                showAddBooking: false,
                successMessage: "Booking Successfully Added!"
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                responseCode: err.response.status,
                errorData: err.response.data.errors,
            })
            this.setState({
                showError: this.state.responseCode === 400 || 422 ? true : false,
                errorMessage: this.state.responseCode === 400 ? 'Slot already taken! ' : ''
            })
        })
    }

     /* Multiselect Functions for Patients*/
    onSelect = (selectedList, selectedItem) => {
        this.setState({
            selectedPatients: selectedList
        })
    }

    onRemove = (selectedList, removedItem) => {
        this.setState({
            selectedPatients: selectedList
        })
    }

    /* Multiselect Functions for Patients*/
    onSelectTreatment = (selectedList, selectedItem) => {
        this.setState({
            // selectedProcedures: selectedList.map(obj => obj.name)
            selectedProcedures: selectedList
        })
    }

    onRemoveTreatment = (selectedList, removedItem) => {
        this.setState({
            // selectedProcedures: selectedList.map(obj => obj.name)
            selectedProcedures: selectedList
        })
    }

    handleUpdateBookingTreatment = () => {

        let formIsValid = true;

        if(this.state.chkboxTreated){
            if(!this.state.ur_number && this.state.probe){
                formIsValid = false;
                this.setState({ errorMessage: "Please add UR number"});
            } else if (!this.state.probe && this.state.ur_number){
                formIsValid = false;
                this.setState({ errorMessage: "Please add probe"});
            }  else if (!this.state.ur_number && !this.state.probe) {
                formIsValid = false;
                this.setState({ errorMessage: "Please add UR number and probe"});
            }
            else {
                formIsValid = true;
            }
        }

        if(formIsValid){
            const dataForUpdateBooking = {
                procedure_id: this.state.procedure,
                ur_number: this.state.ur_number,
                probe: this.state.probe,
                notes: this.state.notes,
                booking_status: this.state.chkboxCancelled ? 0 : 1,
                treated: this.state.chkboxTreated
            }
    
            request('PUT', `/bookings/bookingUpdate/${this.state.official_booking_id}`, dataForUpdateBooking, true).then(res => {
                this.getSummaryList(moment(this.state.booking_date).format("YYYY-MM-DD"), this.state.doctorId, this.state.hospitalId, this.state.slot)
                this.setState({
                    showPatientsModal: true,
                    showUpdateTreatment: false,
                    updatePatientTreatment: true
                })
            }).catch(err => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    error: err.response.data,
                    responseCode: err.response.status,
                    errorData: err.response.data.errors,
                })
            })
        }

    }

    handleContinueCreatingPatient = (e) => {

        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            second_name: this.state.second_name,
            date_of_birth: this.state.date_of_birth,
            address_1: this.state.address_1,
            address_2: this.state.address_2,
            suburb: this.state.suburb,
            state: this.state.state,
            post_code: this.state.post_code,
            procedure: this.state.procedure
        }
        request('POST', '/patients/continue-creating-duplicate-patient', data, true).then(res => {
            this.setState({
                showErrorForExistingPatient: false,
                isLoading: false, 
                showSuccessModal: true,
                successMessage: "Patient Successfully Added!",
                showAddPatientModal: false,
                newPatientId: res.data.data.ID,
                newPatientName: res.data.data.title + '. ' + res.data.data.first_name + ' ' + res.data.data.second_name + ' ' + res.data.data.last_name,
                newPatientProcedure: res.data.data.procedure,
            }, () => {
                console.log(this.state.newPatientName)
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorData: err.response.data.errors,
                showError: true,
            })
        })
    }

    handleAddStaff = (booking_date, booking_doctor, booking_hospital, booking_slot, booking_procedure) => {
        this.setState({
            showAddStaffModal: true,
            staff_heading: "Add Staff",
            staff_button: "Add",
            add_staff_date: booking_date,
            add_staff_doctor_id: booking_doctor,
            add_staff_hospital_id: booking_hospital,
            add_staff_slot: booking_slot,
            add_staff_procedure: booking_procedure,
            this_is_add_staff_function: 1
        })
    }

    handleStaff = e => {
        this.setState({
            staff_id: e.target.value
        })
    }

    handleSaveStaff = () => {
        request('GET', `/bookings/staff/AddStaff?booking_date=${this.state.add_staff_date}&booking_doctor=${this.state.add_staff_doctor_id}&booking_hospital=${this.state.add_staff_hospital_id}&booking_slot=${this.state.add_staff_slot}&staff_id=${this.state.staff_id}&booking_procedure=${this.state.add_staff_procedure}`, {}, true).then(res => {
            // console.log("save", res)
            this.setState({
                isLoading: false,
                showSuccessModal: true,
                showAddStaffModal: false,
                successMessage: this.state.this_is_add_staff_function ? "Staff Successfully Added!" : "Staff Successfully Updated!"
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                responseCode: err.response.status,
                errorData: err.response.data.errors,
            })
        })
    }

    /* Function for Doctor Dropdown*/
    handleDoctor = e => {
        const id = e.target.value;
        if (id === "-Select-") {
            return false
        }
        const index = this.state.doctors.findIndex(doctor => doctor.id === id);
        const doctor = this.state.doctors[index];
        this.setState({
            hospitals: doctor.hospitals,
            currentDoctorId: id
        })
    }

    handleCalendarData = (e) => {
        const calendarData = e.target.value;
        this.setState({
          calendarData: calendarData
        })
        if(calendarData == 'booking-cycle'){
          this.init();
        } else {
          this.setState({
            isLoading: true
          })
          request("GET", "/bookings/secretary/off-list/secAllOffListBookings", {}, true).then((result) => {
            console.log("off-list", result);
            const bookings = result.data.data;
            this.setState({
              bookingsByDate: bookings,
              isLoading: false,
            });
          });
        }
      }

    /* Function for Hospital Dropdown*/
    handleHospital = e => {
        const hospitalId = e.target.value;
        this.setState({ currentHospitalId: hospitalId })
    }

    /* Function for Slot Dropdown*/
    handleSlotForSearch = e => {
        const slotValue = e.target.value;
        if (slotValue === "-Select-") {
            return false
        }
        this.setState({
            slot: slotValue,
            searchIndicator: true,
            booking_date_collection: []
        }, () => {
            this.showBookingCycles();
        })
    }

    handleApplyFilter = () => {
        this.setState({
            isLoading: true
        })     
        const data = {
            currentDoctorId: this.state.currentDoctorId,
            currentHospitalId: this.state.currentHospitalId,
            slot: this.state.slot,
            start_date: this.state.start_date,
            end_date: this.state.end_date
        }

        this.getBookingCycleFilter(data);

        // if(this.state.calendarData == 'booking-cycle'){
        //     this.getBookingCycleFilter(data);
        // } else {
        //     this.getBookingCycleOffListFilter(data);
        // }
    }

    getBookingCycleFilter(data){
        request('POST', `/booking_cycles/secretary/apply-filter`, data, true).then(result => {
            console.log('@handleApplyFilter', result)
            const bookings = result.data.data;
            this.setState({
                bookingsByDate: result.data.data,
                isLoading: false
            });
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                responseCode: err.response.status,
                errorData: err.response.data.errors,
            })
        });
    }

    // getBookingCycleOffListFilter(data){
    //     request("POST", `/booking_cycles/secretary/off-list/apply-filter`, data, true).then(
    //         (result) => {
    //             console.log("@handleApplyFilter.off-list", result);
    //             const bookings = result.data.data;
    //             this.setState({
    //             bookingsByDate: result.data.data,
    //             isLoading: false,
    //             });
    //         }
    //     );
    // }

    /* Function for Filter in Calendar */
    handleClickStartDate = e => {
        const start_date = e.target.value;
        this.setState({ start_date: start_date, final_bookings: [], final_procedures: [] })
    }

    handleClickEndDate = e => {
        const end_date = e.target.value;
        this.setState({ end_date: end_date, final_bookings: [], final_procedures: [] })
    }


    /* Modal Functions*/
    hideModalShowPatients = () => {
        this.setState({
            showPatientsModal: false
        })
        this.state.updatePatientTreatment ? window.location.href = '/secretarypage' : this.setState({ showPatientsModal: false })
    }
    
    handleNewPatient = e => {
        this.setState({ showAddPatientModal: true })
    }

    handleOldPatient = e => {
        this.setState({ showAddBooking: true, showAddPatientModal: false })
    }

    handleAddPatientToAvailBooking = (date) => {

        this.setState({
            showEmptyBookingPage: true,
            showPatientsModal: false,
            booking_date: date,
            currentDoctorId: '',
            currentHospitalId: ''
        })

        request('GET', `/booking_cycles/getDoctorsWithBookingCycleUsingDate/${date}`, {}, true).then(res => {
            console.log("save", res)
            const doctors_empty_booking = res.data.data.map(elem => ({
                name:  elem.doctor.title + '. ' + elem.doctor.first_name + ' ' + elem.doctor.last_name,
                id: elem.doctor.id,
                hospitalName: elem.hospital.name,
                hospitalId: elem.hospital.id
            }))
            this.setState({
                doctors: doctors_empty_booking
            })
        })        
    }

    handleAddPatient = (e) => {
        const data = {
            title: this.state.title,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            second_name: this.state.second_name,
            date_of_birth: this.state.date_of_birth,
            address_1: this.state.address_1,
            address_2: this.state.address_2,
            suburb: this.state.suburb,
            state: this.state.state,
            post_code: this.state.post_code,
            procedure_id: this.state.procedure_id,
            // doctor_id: this.state.doctor_id
            doctor_id: this.state.doctorId
            
        }
        request('POST', '/patients/', data, true).then(res => {
            this.setState({
                isLoading: false,
                show: false,
                showSuccessForAddPatient: true,
                successMessageAddPatient: "Patient Successfully Added!",
                showAddPatientModal: false,
                newPatientId: res.data.data.ID,
                newPatientName: res.data.data.title + ". " + res.data.data.first_name +  " " + res.data.data.second_name + " " + res.data.data.last_name,
                newPatientProcedureId: res.data.data.procedure,
                newPatientProcedureName: res.data.data.procedure_name
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                errorData: err.response.data.errors,
                showError: true,
                errorCode: err.response.status,
                patientErrorData: err.response.data.message
            }, () => {
                this.setState({
                    showError: this.state.errorCode == 409? false : true
                }, () => {
                    this.setState({
                        showErrorForExistingPatient: this.state.showError ? false : true
                    })
                })
            })
        })
    }

    hideAddPatientModal = () => {
        this.setState({ showAddPatientModal: false })
    }

    hideModalSuccessAddPatient = () => {
        this.setState({
            showSuccessForAddPatient: false,
            newPatientId: this.state.newPatientId,
            newPatientName: this.state.newPatientName,
            newPatientProcedureId: this.state.newPatientProcedureId,
            newPatientProcedureName: this.state.newPatientProcedureName,
            showAddBooking: true,
        }, () => {
            if (this.state.newPatientId !== null && this.state.newPatientName !== null) {
                this.setState({
                  selectedPatients: [{ id: this.state.newPatientId, name: this.state.newPatientName }],
                  selectedProcedures: [{ id: this.state.newPatientProcedureId, name: this.state.newPatientProcedureName }],
                  selectedProcedure: [{ name: this.state.newPatientProcedureName }],
                  selectedPatient: [{ name: this.state.newPatientName }]
                })
              }
        })
    }

    hideModal = e => {
        this.setState({ showDeleteModal: false, show: false })
    }

    hideModalSuccess = () => {
        this.setState({ showSuccessModal: false })
        window.location.href = "/secretarypage";
    }

    hideModalError = () => {
        this.setState({ showError: false })
    }

    hideErrorForExistingPatient = () => {
        this.setState({
            showErrorForExistingPatient: false,
        })
    }

    hideAddStaffModal = e => {
        this.setState({
            showAddStaffModal: false
        })
    }

    handleUpdateStaffModal = (booking_date, booking_doctor, booking_hospital, booking_slot, booking_staff_id, booking_procedure) => {
        this.setState({
            showAddStaffModal: true,
            staff_heading: "Update Staff",
            staff_button: "Update",
            add_staff_date: booking_date,
            add_staff_doctor_id: booking_doctor,
            add_staff_hospital_id: booking_hospital,
            add_staff_slot: booking_slot,
            staff_id: booking_staff_id,
            add_staff_procedure: booking_procedure,
            this_is_add_staff_function: 0
        })
    }

    handleDeleteStaffModal = (booking_date, booking_doctor, booking_hospital, booking_slot, booking_staff_id, booking_procedure) => {
        this.setState({
            showDeleteModal: true,
            add_staff_date: booking_date,
            add_staff_doctor_id: booking_doctor,
            add_staff_hospital_id: booking_hospital,
            add_staff_slot: booking_slot,
            add_staff_procedure: booking_procedure,
            staff_id: booking_staff_id
        })
    }

    hideModal = e => {
        this.setState({ showDeleteModal: false, show: false })
    }

    deleteHandler = e => {
        request('GET', `/bookings/staff/deleteStaff?booking_date=${this.state.add_staff_date}&booking_doctor=${this.state.add_staff_doctor_id}&booking_hospital=${this.state.add_staff_hospital_id}&booking_slot=${this.state.add_staff_slot}&staff_id=${this.state.staff_id}`, {}, true).then(res => {
            console.log("delete", res)
            this.setState({
                isLoading: false,
                showSuccessModal: true,
                showDeleteModal: false,
                successMessage: "Staff Successfully Deleted!"
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data,
                responseCode: err.response.status,
                errorData: err.response.data.errors,
            })
        })

    }

    hideUpdateTreatment = () => {
        this.setState({
            showUpdateTreatment: false
        })
    }

    getResizeValues = (newResized, event) => {
        if(newResized){
            console.log(localStorage.getItem('secretaryBookingTableWidths'), newResized)
            if(localStorage.getItem('secretaryBookingTableWidths')) {
                const widths = JSON.parse(localStorage.getItem('secretaryBookingTableWidths'));
                newResized.forEach(element => {
                    const i = widths.findIndex(width => width.id == element.id);
                    if(i > -1) {
                        const width = widths[i];
                        width.value = element.value
                    } else {
                        widths.push(element);
                    }
                });
                localStorage.setItem("secretaryBookingTableWidths", JSON.stringify(widths));

            } else {
                localStorage.setItem("secretaryBookingTableWidths", JSON.stringify(newResized));
            }
        }
    }

    getColumnWidth(columnId, defaultSize) {
        if(localStorage.getItem('secretaryBookingTableWidths')) {
            const widths =  JSON.parse(localStorage.getItem('secretaryBookingTableWidths'));
            const i = widths.findIndex((element) => element.id == columnId);
            if(i > -1) {
                const columnWith = widths[i].value;
                return columnWith;
            }
            return defaultSize;
        }
        return defaultSize;
    }

    handleTreated = (e) => {
        this.setState({
          chkboxTreated: !this.state.chkboxTreated,
        });
    
        if (e.target.checked === true) {
          this.setState({
            chkboxTreated: 1,
            disableFunctionCancelled: true,
          });
        } else {
          this.setState({
            chkboxTreated: 0,
            disableFunctionCancelled: false,
          });
        }
      }

      handleCancelled = (e) => {
        this.setState({
          chkboxCancelled: !this.state.chkboxCancelled,
        });
    
        if (e.target.checked === true) {
          this.setState({
            booking_status: 0,
            disableFunctionTreated: true,
          });
        } else {
          this.setState({
            booking_status: 1,
            disableFunctionTreated: false,
          });
        }
      }

      handleDoctorId = (e) => {
        // console.log(e.target.value)
        const docId = e.target.value;
    
        if(e.target.value === "-Select-"){
            return false;
        }
    
        this.setState({
            doctor_id: e.target.value,
            currentDoctorId: e.target.value,
            start_dates: [],
            end_dates: [],
            moveBookingProcedures: [],
            selectedTreatment: [],
            offListDate: [],
            isLoadingCalendar: true
        })
    
        document.getElementById('hospital_id').value = 'Select All';
    
        const index = this.state.doctorsWithBookingCycles.findIndex(info => info.doctorId === e.target.value);
        const doctor = this.state.doctorsWithBookingCycles[index];
        if(doctor){
            this.getBookingCyclesInformation(docId, doctor.week, doctor.column_start_date, doctor.column_end_date)
        }

        // if(doctor.date){
        //   const offListProcedures = doctor.procedures.map((procedure, i) => ({ 
        //       name: procedure.procedure_name, 
        //       id: procedure.procedure_id
        //   }));      
        //   this.setState({
        //       currentDoctorId: doctor.doctorId,
        //       currentHospital: doctor.hospitalName,
        //       currentHospitalId: doctor.hospitalId,
        //       hospitalId: doctor.hospitalId,
        //       moveBookingProcedures: offListProcedures,
        //     //   offListDate: doctor.date,
        //       slot: doctor.slot,
        //       multipleDoctor: 0
        //   }, () => {
        //     this.setState({
        //         // procedureName: this.state.moveBookingProcedures.map(obj => obj.name),
        //         procedureName: this.state.moveBookingProcedures,
        //         date: doctor.date
        //         // date: this.state.offListDate
        //     }, () => {
        //         this.state.offListDate.push(this.state.date)
        //     })
        //   })
    
        //   const hospId = doctor.hospitalId;
        //   this.bookingsForCalendar(docId, hospId);
    
        // } else {
        //   request('GET', `/booking_cycles/getBydoctorId/getBookingCycleBydoctorId/${e.target.value}`, {}, true).then((result, i) => {
        //       console.log('DoctorChoice', result.data.data.length)
        //       if(result.data.data.length === 1){
        //           this.setState({
        //               multipleDoctor: 0,
        //               finalProcedure: [],
        //               slot: "",
        //               isLoadingCalendar: false
        //           })
    
        //           const second_index = this.state.doctorsWithBookingCycles.findIndex(info => info.doctorId === this.state.doctor_id);
        //           const second_doctor = this.state.doctorsWithBookingCycles[second_index];
        //           const currentProceduresSingleData = second_doctor.procedures.map((procedure, i) => ({ 
        //               name: procedure.procedure_name, 
        //               id: procedure.procedure_id
        //           }));    
        //           this.setState({
        //               currentDoctorId: second_doctor.doctorId,
        //               currentHospital: second_doctor.hospitalName,
        //               currentHospitalId: second_doctor.hospitalId,
        //               moveBookingProcedures: currentProceduresSingleData,
        //               slot: second_doctor.slot,
        //               currentDay: second_doctor.day
        //           }, async () => {
                      
        //               const hospId = second_doctor.hospitalId;
        //               const dayForDate = second_doctor.day;
    
        //               //Check bookings is existing
        //               this.bookingsForCalendar(docId, hospId);
        //               const weekNumberToString = doctor.week;
    
        //               const weekNumber = weekNumberToString  === "4" || weekNumberToString  === 4 ? 0 : weekNumberToString;
    
        //               if(dayForDate === "Sunday"){
        //                   let dayAddNumber = 6;
        //                   await this.getWeekNumber(weekNumber, 0, dayAddNumber);                        
        //               }
        //               if(dayForDate === "Monday"){
        //                   let dayAddNumber = 0;
        //                   await this.getWeekNumber(weekNumber, 0, dayAddNumber);
    
        //               }
        //               if(dayForDate === "Tuesday"){
        //                   let dayAddNumber = 1;
        //                   await this.getWeekNumber(weekNumber, 0, dayAddNumber);
        //               }
        //               if(dayForDate === "Wednesday"){
        //                   let dayAddNumber = 2;
        //                   await this.getWeekNumber(weekNumber, 0, dayAddNumber);
        //               }
        //               if(dayForDate === "Thursday"){
        //                   let dayAddNumber = 3;
        //                   await this.getWeekNumber(weekNumber, 0, dayAddNumber);
        //               }
        //               if(dayForDate === "Friday"){
        //                   let dayAddNumber = 4;
        //                   await this.getWeekNumber(weekNumber, 0, dayAddNumber);
        //               }
        //               if(dayForDate === "Saturday"){
        //                   let dayAddNumber = 5;
        //                   await this.getWeekNumber(weekNumber, 0, dayAddNumber);
        //               } 
    
        //               this.setState({
        //                 //   procedureName: this.state.moveBookingProcedures.map(obj => obj.name)
        //                 procedureName: this.state.moveBookingProcedures

        //               })                            
        //           });
    
        //       } else{
        //           this.setState({
        //               multipleDoctor: 1,
        //               moveBookingProcedures: [],
        //               slot: "",
        //               isLoadingCalendar: false
        //           })
    
        //           request('GET', `/doctor_hospitals/byDoctorIdWithBookingCycle/${this.state.doctor_id}`, {}, true).then((result, i) => { 
        //               // console.log('hospitalsResults', result)
        //               const hospitalsForMoveBooking = result.data.data.map(obj => ({
        //                   hospitalName: obj.name,
        //                   hospitalId: obj.id,
        //                   hospitalStatus: obj.status
        //               }));
        //               this.setState({ hospitalsForMoveBooking }); 
        //           })
    
        //       }                       
        //   }).catch(err => {
        //       console.error(err.response);
        //       this.setState({
        //           isLoading: false,
        //           error: err.response
        //       })
        //   })
        // }

      }

    initSingleData = (docId, doctor_week, doctor_column_start_date, doctor_column_end_date) => {
        this.setState({
            multipleDoctor: 0,
            finalProcedure: [],
            slot: ""
        })

        const second_index = this.state.doctorsWithBookingCycles.findIndex(info => info.doctorId === this.state.doctor_id);
        const second_doctor = this.state.doctorsWithBookingCycles[second_index];
        const currentProceduresSingleData = second_doctor.procedures.map((procedure, i) => ({ 
            name: procedure.procedure_name, 
            id: procedure.procedure_id
        })); 
        
        this.setState({
            currentDoctorId: second_doctor.doctorId,
            currentHospital: second_doctor.hospitalName,
            currentHospitalId: second_doctor.hospitalId,
            finalProcedure: this.props.location.state ? this.state.propsFinalProcedure : currentProceduresSingleData,
            slot: second_doctor.slot,
            currentDay: second_doctor.day
        }, async () => {
            
            const hospId = second_doctor.hospitalId;
            const dayForDate = second_doctor.day;

            //Check bookings is existing
            this.bookingsForCalendar(docId, hospId);
            const weekNumberToString = doctor_week;

            const weekNumber = weekNumberToString  === "4" || weekNumberToString  === 4 ? 0 : weekNumberToString;
                // console.log("details: ", dayForDate, weekNumber)
                if(second_doctor.date){
            
                    this.state.offListDate.push(second_doctor.date)

                } else { 


                    if(dayForDate === "Sunday"){
                        let dayAddNumber = 6;
                        await this.getWeekNumber(weekNumber, "Sunday", doctor_column_start_date, doctor_column_end_date);    
                    }
                    if(dayForDate === "Monday"){
                            let dayAddNumber = 0;
                            await this.getWeekNumber(weekNumber, "Monday", doctor_column_start_date, doctor_column_end_date);
                    }
                    if(dayForDate === "Tuesday"){
                            let dayAddNumber = 1;
                            await this.getWeekNumber(weekNumber, "Tuesday", doctor_column_start_date, doctor_column_end_date);
                    }
                    if(dayForDate === "Wednesday"){
                            let dayAddNumber = 2;
                            await this.getWeekNumber(weekNumber, "Wednesday", doctor_column_start_date, doctor_column_end_date);
                    }
                    if(dayForDate === "Thursday"){
                            let dayAddNumber = 3;
                            await this.getWeekNumber(weekNumber, "Thursday", doctor_column_start_date, doctor_column_end_date);
                    }
                    if(dayForDate === "Friday"){
                            let dayAddNumber = 4;
                            await this.getWeekNumber(weekNumber, "Friday", doctor_column_start_date, doctor_column_end_date);
                    }
                    if(dayForDate === "Saturday"){
                            let dayAddNumber = 5;
                            await this.getWeekNumber(weekNumber, "Saturday", doctor_column_start_date, doctor_column_end_date);
                    } 
                    
                } 
            
            this.setState({
                // procedureName: this.props.location.state ? this.state.propsFinalProcedure.map(obj => obj.name) : this.state.finalProcedure.map(obj => obj.name)
                procedureName: this.props.location.state ? this.state.propsFinalProcedure : this.state.finalProcedure,
                isLoadingCalendar: false
            })                            
        });
    }

    initBookingCyclesInfo = (docId) => {
        return request('GET', `/booking_cycles/getBydoctorId/getBookingCycleBydoctorId/${docId}`, {}, true);
    }

    getBookingCyclesInformation = (docId, doctor_week, doctor_column_start_date, doctor_column_end_date) => {

        this.initBookingCyclesInfo(docId).then((result, i) => {

            // console.log("getBookingCyclesInformation length", result.data.data.length);

            if(result.data.data.length === 1){
                
                this.initSingleData(docId, doctor_week, doctor_column_start_date, doctor_column_end_date);

            } else {

                this.setState({
                    multipleDoctor: 1,
                    finalProcedure: this.props.location.state ? this.state.propsFinalProcedure : [],
                    slot: ""
                })

                request('GET', `/doctor_hospitals/byDoctorIdWithBookingCycle/${docId}`, {}, true).then((result, i) => { 
                    // console.log('doctor_hospitalsResults', result.data.data.length)

                    if(result.data.data.length === 1){

                        this.setState({
                            multipleDoctor: 0,
                            finalProcedure: [],
                            slot: ""
                        })
                
                        const second_index = this.state.doctorsWithBookingCycles.findIndex(info => info.doctorId === this.state.doctor_id);
                        const second_doctor = this.state.doctorsWithBookingCycles[second_index];
                            
                        this.setState({
                            currentHospital: second_doctor.hospitalName,
                            currentHospitalId: second_doctor.hospitalId,
                        });
                    
                        this.getBookingsByHospitalDoctor(this.state.currentHospitalId, docId);
        
                    } else {

                        const hospitalsForMoveBooking = result.data.data.map(obj => ({
                            hospitalName: obj.name,
                            hospitalId: obj.id,
                            hospitalStatus: obj.status
                        }));

                        this.setState({ hospitalsForMoveBooking, isLoadingCalendar: false });

                    }
                })

            }                       
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })

    }

    getBookingsByHospitalDoctor = (hospital_id, doctor_id) => {
        let dateCounter = 0;
        request('GET', `/booking_cycles/getByHospAndDoc/${hospital_id}/${doctor_id}`, {}, true).then(result => {
            // console.log("getByHospAndDoc", result)
            const booking_cycles = result.data.data.map(obj => ({
                cycleId: obj.id,
                procedures: obj.booking_cycle_procedures ? obj.booking_cycle_procedures.map(obj => ({ id: obj.procedure_id, name: obj.procedure_name })) :  obj.booking_cycle_offlist_procedures.map(obj => ({ id: obj.procedure_id, name: obj.procedure_name })),
                slot: obj.slot,
                week: obj.week,
                day: obj.day,
                date: obj.date,
                column_start_date: obj.start_date,
                column_end_date: obj.end_date
            }));
    
            this.setState({
                booking_cycles           
            }, async () => {
                for (let index = 0; index < this.state.booking_cycles.length; index++) {
                    dateCounter++;
                   let dayAddNumber = 0;
                   let indexed_booking_cycle = this.state.booking_cycles[index];
                   console.log("details: ", indexed_booking_cycle.day, indexed_booking_cycle.week)
                    if(booking_cycles[index].date){
                        // console.log(booking_cycles[index].date)
                        this.state.offListDate.push(this.state.booking_cycles[index].date)
                    } else {
                        
                        if(indexed_booking_cycle.day === "Sunday"){
                            let dayAddNumber = 6;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Sunday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        
                        }
                        if(indexed_booking_cycle.day === "Monday"){
                            let dayAddNumber = 0;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Monday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        
    
                        }
                        if(indexed_booking_cycle.day === "Tuesday"){
                            let dayAddNumber = 1;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Tuesday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        
    
                        }
                        if(indexed_booking_cycle.day === "Wednesday"){
                            let dayAddNumber = 2;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Wednesday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        
    
                        }
                        if(indexed_booking_cycle.day === "Thursday"){
                            let dayAddNumber = 3;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Thursday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        
    
                        }
                        if(indexed_booking_cycle.day === "Friday"){
                            let dayAddNumber = 4;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Friday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        
    
                        }
                        if(indexed_booking_cycle.day === "Saturday"){
                            let dayAddNumber = 5;
                            await this.getWeekNumber(indexed_booking_cycle.week, "Saturday", indexed_booking_cycle.column_start_date, indexed_booking_cycle.column_end_date);                        
                        } 
                    }
                }
                this.setState({
                    offListDate: this.state.offListDate,
                    isLoadingCalendar: false
                })
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
    }


      handleHospitalId = e => {
        const hospitalId = e.target.value;
        this.setState({
            hospitalId: hospitalId,
            currentHospitalId: hospitalId,
            week_start_dates: [],
            week_end_dates: [],
            start_dates: [],
            end_dates: [],
            dayAddNumberCollection: [],
            moveBookingProcedures: [],
            selectedTreatment: [],
            offListDate: [],
            isLoadingCalendar: true
        })

        this.getBookingsByHospitalDoctor(hospitalId, this.state.doctor_id);
        this.bookingsForCalendar(this.state.doctor_id, hospitalId);
    
        // let dateCounter = 0;
        // request('GET', `/booking_cycles/getByHospAndDoc/${hospitalId}/${this.state.doctor_id}`, {}, true).then(result => {
        //     console.log("getByHospAndDoc", result)
        //     const booking_cycles = result.data.data.map(obj => ({
        //         cycleId: obj.id,
        //         procedures: obj.booking_cycle_procedures ? obj.booking_cycle_procedures :  obj.booking_cycle_offlist_procedures,
        //         slot: obj.slot,
        //         week: obj.week,
        //         day: obj.day,
        //         date: obj.date
        //     }));
    
        //     this.setState({
        //         booking_cycles           
        //     }, async () => {
        //         for (let index = 0; index < this.state.booking_cycles.length; index++) {
        //             dateCounter++;
        //             console.log(this.state.booking_cycles[index])
        //             if(booking_cycles[index].date){
        //                 this.state.offListDate.push(booking_cycles[index].date)
        //                 // this.setState({ 
        //                 //     offListDate: this.state.booking_cycles[index].date
        //                 // })
        //             } else {
        //                 if(this.state.booking_cycles[index].day === "Sunday"){
        //                     let dayAddNumber = 6;
        //                     await this.getWeekNumber(this.state.booking_cycles[index].week, dateCounter, dayAddNumber);                        
        //                 }
        //                 if(this.state.booking_cycles[index].day === "Monday"){
        //                     let dayAddNumber = 0;
        //                     await this.getWeekNumber(this.state.booking_cycles[index].week, dateCounter, dayAddNumber);
    
        //                 }
        //                 if(this.state.booking_cycles[index].day === "Tuesday"){
        //                     let dayAddNumber = 1;
        //                     await this.getWeekNumber(this.state.booking_cycles[index].week, dateCounter, dayAddNumber);
        //                 }
        //                 if(this.state.booking_cycles[index].day === "Wednesday"){
        //                     let dayAddNumber = 2;
        //                     await this.getWeekNumber(this.state.booking_cycles[index].week, dateCounter, dayAddNumber);
        //                 }
        //                 if(this.state.booking_cycles[index].day === "Thursday"){
        //                     let dayAddNumber = 3;
        //                     await this.getWeekNumber(this.state.booking_cycles[index].week, dateCounter, dayAddNumber);
        //                 }
        //                 if(this.state.booking_cycles[index].day === "Friday"){
        //                     let dayAddNumber = 4;
        //                     await this.getWeekNumber(this.state.booking_cycles[index].week, dateCounter, dayAddNumber);
        //                 }
        //                 if(this.state.booking_cycles[index].day === "Saturday"){
        //                     let dayAddNumber = 5;
        //                     await this.getWeekNumber(this.state.booking_cycles[index].week, dateCounter, dayAddNumber);
        //                 } 
        //             }
        //         }
        //         this.setState({
        //             offListDate: this.state.offListDate
        //         })
        //     })
        // }).catch(err => {
        //     console.error(err.response);
        //     this.setState({
        //         isLoading: false,
        //         error: err.response
        //     })
        // })

      }


      bookingsForCalendar = (doctorId, hospitalId) => {
        request('GET', `/bookings/appointment/${hospitalId}/${doctorId}`, {}, true).then(result => { 
            // console.log('bookings', result)
            const cal_bookings = result.data.data.map(booking => {
                this.setState({
                    booking_dates: [...this.state.booking_dates, booking.date],
                    isLoadingCalendar: false
                })
            }); 
        }).catch(err => {
          console.error(err.response);
          this.setState({
              isLoadingCalendar: false,
              error: err.response
          })
        })
      }

    //Multiselect of Treatment/Procedure
    moveBookingOnSelectTreatment = (selectedList, selectedItem) => {
        this.setState({
            // selectedTreatment: selectedList.map(obj => obj.name)
            selectedTreatment: selectedList
        })
    }

    moveBookingOnRemoveTreatment = (selectedList, selectedItem) => {
        this.setState({
            selectedTreatment: selectedList
            // selectedTreatment: selectedList.map(obj => obj.name)
        })
    }

    // getWeekNumber(currentWeekNumber, dateCounter, dayAddNumber){
    getWeekNumber(currentWeekNumber, day, column_start_date, column_end_date){
        return new Promise((resolve, reject) => {

            const date_data = {
                start_date: column_start_date,
                end_date: column_end_date,
                day: day
            }
      
            const weekNumber = currentWeekNumber === 4 ? 0 : currentWeekNumber;
            // this.state.dayAddNumberCollection.push(dayAddNumber);
            request('POST', `/booking_cycles/getDatesByWeekNumber/${weekNumber}`, date_data, true).then((result) => { 
                // console.log("getDatesByWeekNumber", result)
                this.setState({
                    start_dates: [ ...this.state.start_dates, result.data.week_start_dates],
                })
                resolve(true);
            }).catch(err => {
                console.error(err.response);
                this.setState({
                    isLoading: false,
                    error: err.response
                })
                reject(err)
            })
        })
    }

    handleMovePatientToOtherBooking = (e) => {

        e.preventDefault();
        this.setState({
          isLoadingForMovingPatient: true,
        })
    
        const data = {
          booking_id: this.state.currentBookingId,
          doctor_id: this.state.currentDoctorId,
          hospital_id: this.state.currentHospitalId,
          slot: this.state.slot,
          date: this.state.date,
            procedure:  this.state.selectedTreatment ? this.state.selectedTreatment : this.state.procedureName,
        //   procedure:  this.state.selectedTreatment.toString() ? this.state.selectedTreatment.toString() : this.state.procedureName.toString(),
        }
    
        request('PUT', '/bookings/movePatientToOtherBookingList', data, true).then(res => {
          console.log('res', res)
            this.setState({
              isLoadingForMovingPatient: false,
              showMoveBooking: false,
              showSuccessModal: true,        
              successMessage: "Patient successfully moved"  
            })
        }).catch((err) => {
          console.error(err.response);
          this.setState({
            isLoading: false,
            error: err.response.data,
            responseCode: err.response.status,
            errorData: err.response.data,
          })
        })
    }

    handleCalendar = (date) => {
        const event_date = moment(date).format("YYYY-MM-DD");
        const current_doctor_id = this.state.doctor_id;
        const current_hospital_id = this.state.hospitalId;
        // console.log("event_date", event_date, "current_doctor_id", current_doctor_id, "current_hospital_id", current_hospital_id)
    
        this.setState({
          date: event_date
        })
    
        return request('GET', `/booking_cycles/getBookingCycleByDate/${event_date}/${current_hospital_id}/${current_doctor_id}`, {}, true).then(result => {

            const dateInformation = result.data.data.map(obj => ({
                slot: obj.slot,
                procedures: obj.booking_cycle_offlist_procedures ? obj.booking_cycle_offlist_procedures : obj.booking_cycle_procedures
            }))

            this.setState({ dateInformation })

            if(result.data.data.length > 1){
                result.data.data.map(value => {
                    if(!this.state.slotOptions.includes(value.slot)){
                        this.state.slotOptions.push(value.slot)
                    }
                })
            } else {
                const one_data = result.data.data[0];
                if(one_data){

                    const procedures = one_data.booking_cycle_procedures ? one_data.booking_cycle_procedures : one_data.booking_cycle_offlist_procedures
                    const currentProcedures = procedures.map((obj, i) => ({
                                                name: obj.procedure_name,
                                                id: obj.procedure_id
                                            }));

                    this.setState({
                        slot: one_data.slot,
                        moveBookingProcedures: this.props.location.state ? this.state.propsFinalProcedure : currentProcedures
                    }, () => {
                        this.setState({
                            // procedureName: this.state.finalProcedure.map(obj => obj.name)
                            procedureName: this.state.finalProcedure
                        })
                    })
                }
            }


            // console.log('dates', result)
            // this.setState({
            //     slot: result.data.data.slot,
            //     procedures: result.data.data.booking_cycle_procedures ? result.data.data.booking_cycle_procedures: result.data.data.booking_cycle_offlist_procedures
            // }, () => {
    
            //     /* For showing of procedures */
            //     const currentProcedures = this.state.procedures.map((obj, i) => ({
            //         name: obj.procedure_name,
            //         id: obj.procedure_id
            //     }));
            //     this.setState({
            //         moveBookingProcedures: this.props.location.state ? this.state.propsFinalProcedure : currentProcedures,
            //         slot: this.state.slot            
            //     }, () => {
            //         this.setState({
            //             // procedureName: this.state.moveBookingProcedures.map(obj => obj.name)
            //             procedureName: this.state.moveBookingProcedures
            //         })
            //     });          
            // })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response
            })
        })
      }

    deletePatientInBookingList = (booking_id) => {
        this.setState({
            showDeleteBookingModal: true,
            showPatientsModal: false,
            currentBookingId: booking_id
        })
    }

    deletePatientInBookingListHandler = (e) =>{
        request('DELETE', `/bookings/${e.target.value}`, {}, true).then(res => {
            this.setState({
                isLoading: true,
            })
            window.location.href = "/bookingscalendar";
        }).catch(err => { 
            console.error(err.response);
            this.setState({
                isLoading: false,
                error: err.response.data
            })
        })
    }

    handleShowModalAddNotes = (date, doctor_id, hospital_id, slot, booking_cycle_id) => {
        // console.log(date, doctor_id, hospital_id, slot, booking_cycle_id)
        this.setState({
            showModalAddNotes: true,
            currentBookingId: booking_cycle_id,
            currentDoctorId: doctor_id,
            currentHospitalId: hospital_id,
            currentSlot: slot,
            date: date
        })
    }

    hideModalAddNotes = () => {
        this.setState({
            showModalAddNotes: false,
            showCalendarNotes: false
        })
    }

    handleAddNotes = () => {

        const data = {
            doctor_id: this.state.currentDoctorId,
            hospital_id: this.state.currentHospitalId,
            slot: this.state.currentSlot,
            date: this.state.date,
            notes: this.state.calendar_notes,
            booking_cycle_id: this.state.currentBookingId
        }

        request('POST', '/booking_cycle_notes', data, true).then((result) => { 
            this.setState({
            showModalAddNotes: false,
            showSuccessModal: true,
            successMessage: result.data.message,
            viewCalendarNotes: 'block',
            showEditCalendarNotes: 'none'

            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                showModalAddNotes: false,
                showError: true,
                errorMessage: "The notes field is required."
            })
        })

    } 

    handleCalendarNotes = (e) => {
        this.setState({
            calendar_notes: e.target.value
        })
    }

    showCalendarNotes = (notes, notes_id) => {
        // console.log("notes", notes)
        this.setState({
            showCalendarNotes: true,
            current_calendar_notes: notes,
            notes_id: notes_id
        })
    }

    handleShowNotesUpdateForm = () => {
        this.setState({
            showEditCalendarNotes: 'block',
            viewCalendarNotes: 'none',
            saveButtonDisplay: 'block',
            updateButtonDisplay: 'none'
        })
    }

    handleSaveEdittedNotes = () => {

        const data = {
            notes: this.state.current_calendar_notes,
        } 

        request('PUT', `/booking_cycle_notes/update-notes/${this.state.notes_id}`, data, true).then((result) => { 
            console.log(result)
            this.setState({
            showCalendarNotes: false,
            showSuccessModal: true,
            successMessage: result.data.message
            })
        }).catch(err => {
            console.error(err.response);
            this.setState({
                showCalendarNotes: false,
                showError: true,
            })
        })

    }

    handleCurrentCalendarNotes = (e) => {
        this.setState({
            current_calendar_notes: e.target.value
        })
    }

    getTrProps = (state, rowInfo, instance) => {
        if (rowInfo) { 
            return {
                style: {
                    color:
                        rowInfo.original.week == 1 ? "#000000" : (rowInfo.original.week == 2 ? "#c10001" : ( rowInfo.original.week == 3 ? '#9900ff' : (rowInfo.original.week == 4 ? '#009900' : '')))
                }
            }
        }
        return {}
    }

    handleSlot = (e) => {
        const current_slot = e.target.value
    
        const slot_index = this.state.dateInformation.findIndex(info => info.slot == current_slot)
        const slot_info = this.state.dateInformation[slot_index];
        const slot_procedures = slot_info.procedures;
    
        const currentProcedures = slot_procedures.map((obj, i) => ({
            name: obj.procedure_name,
            id: obj.procedure_id
        }));
    
        this.setState({
          moveBookingProcedures: this.props.location.state ? this.state.propsFinalProcedure : currentProcedures,
          slot: current_slot            
        }, () => {
            this.setState({
                procedureName: this.state.moveBookingProcedures
            })
        })
       
    }

    
    render() {
        moment.locale("en-GB");
        // const localizer = momentLocalizer(moment);
        let formats = {
            timeGutterFormat: "A"
        }

        const mutiselectStyle = {
            option: { 
                color: "black",
            },
        };

        const columns = [{
            Header: 'Date',
            accessor: 'date',  // String-based value accessors!
            Cell: row => {
                return <React.Fragment>
                    <div className="cell-text">{moment(row.original.date).format('dddd, DD-MMMM-YYYY')}</div>
                </React.Fragment>
            },
            width: this.getColumnWidth('date', 250),
            id: "date",
        },

        {
            Header: 'AM/PM',
            accessor: 'slot',
            width: this.getColumnWidth('slot', 150),
            id: "slot",
            Cell: row => {
                // console.log(row)
                return <React.Fragment>
                    {
                        Object.keys(row.original.items).sort().map((key, i) => {
                            return <React.Fragment key={i}>
                                <div style={{height: this.getSlotHeight(row.original.items[key])}} className="cell-text">{key}</div>
                                {
                                  Object.keys(row.original.items).length -1 != i && <hr className="m-0" />
                                }
                            </React.Fragment>
                        })
                    }
                </React.Fragment>
            }
        },

        {
            Header: 'Doctor',
            accessor: 'doctor',
            width: this.getColumnWidth('doctor', 200),
            id: "doctor",
            Cell: row => {
                // console.log(row)
                return <React.Fragment>
                    {
                        Object.keys(row.original.items).sort().map((key, itemIndex) => {
                            return <React.Fragment key={itemIndex}>
                                {
                                    row.original.items[key].map((doctor, i) => {
                                        // console.log((row.original.items[key].length - 1 != i && Object.keys(row.original.items).length -1 != itemIndex))
                                        return <React.Fragment key={i}>
                                            <div style={{height: `${this.getDoctorHeight(doctor)}px`}} className="cell-text">
                                                {doctor.name}
                                            </div>
                                            {(Object.keys(row.original.items).length -1 != itemIndex) && <hr className="m-0" />}
                                        </React.Fragment>

                                    })
                                }
                            </React.Fragment>
                        })
                    }
                </React.Fragment>
            }
        },

        {
            Header: 'Hospital',
            accessor: 'hospital',
            width: this.getColumnWidth('hospital', 200),
            id: "hospital",
            Cell: row => {
                // console.log(row)
                return <React.Fragment>
                   {
                        Object.keys(row.original.items).sort().map((key, itemIndex) => {
                            return <React.Fragment key={itemIndex}>
                                {
                                    row.original.items[key].map((doctor, i) => {
                                        return <React.Fragment key={i}>
                                           <div >
                                           {
                                                doctor.items.map((hospital, i) => {
                                                    return <React.Fragment key={i}>
                                                    <div style={{height: `${this.getHospitalHeight(hospital)}px`}}  className="cell-text">
                                                        {
                                                            hospital.name
                                                        }
                                                    </div>
                                                    {(doctor.items.length -1 != i) && <hr className="m-0" />}
                                                    </React.Fragment>
                                                })
                                            }
                                           </div>

                                           {(Object.keys(row.original.items).length -1 != itemIndex) && <hr className="m-0" />}

                                        </React.Fragment>

                                    })
                                }
                            </React.Fragment>
                        })
                   }
                </React.Fragment>
            }
        },

        {
            Header: 'Summary',
            accessor: 'summary',
            width: this.getColumnWidth('summary', 200),
            id: "summary",
            Cell: row => <React.Fragment>
                 {
                        Object.keys(row.original.items).sort().map((key, itemIndex) => {
                            return <React.Fragment key={itemIndex}>
                                {
                                    row.original.items[key].map((doctor, i) => {
                                        return <React.Fragment key={i}>
                                           <div>
                                           {
                                                doctor.items.map((hospital, i) => {
                                                    return <React.Fragment key={i}>
                                                    <div >

                                                        <React.Fragment>
                                                            {
                                                                hospital.items.map((obj, i) => {
                                                                    return <div key={i} style={{height: 50}} className="cell-text">
                                                                        {`${obj.count}x ${obj.procedure_name}`}
                                                                    </div>
                                                                })
                                                            }
                                                        </React.Fragment>
                                                            
                                                    </div>

                                                    {(doctor.items.length -1 != i) && <hr className="m-0" />}

                                                    </React.Fragment>
                                                })
                                            }
                                           </div>

                                           {(Object.keys(row.original.items).length -1 != itemIndex) && <hr className="m-0" />}

                                        </React.Fragment>

                                    })
                                }
                            </React.Fragment>
                        })
                   }
            </React.Fragment>
        }, 

        {
            Header: "Notes",
            width: this.getColumnWidth('notes', 200),
            id: "notes",
            accessor: "notes",
            Cell: (row) => (
              <React.Fragment>
                {row.original.items.length != 0 ? (
                  Object.keys(row.original.items).sort().map((master_key, itemIndex) => {
                      return (
                        <React.Fragment key={itemIndex}>
                          {row.original.items[master_key].map((doctor, i) => {
                            
                            return (
                              <React.Fragment key={i}>
                                <div>
                                  {
                                    doctor.items.map((hospital, i) => {
                                      console.log('hospital', hospital)
                                      console.log('hospital.items', hospital.items)
                                      console.log('hospital.items[0]', hospital.items[0])
                                      if (hospital.items.length === 0) {
                                        return (
                                          <React.Fragment key={i}>
                                          </React.Fragment>
                                        )
                                      }
                                      const cycle_id = hospital.items[0].hasOwnProperty('booking_cycle_id') ? hospital.items[0].booking_cycle_id : hospital.items[0].booking_cycle_off_list_id
                                      return (
                                        <React.Fragment key={i}>
                                          <div style={{ height: `${this.getHospitalHeight( hospital )}px` }} className="cell-text text-center">
                                            {
                                              doctor.notes ? (
                                                <React.Fragment>
                                                  {/* <p>{doctor.notes}</p> */}
                                                    <Link onClick={() => this.showCalendarNotes(doctor.notes, doctor.notes_id)}><span className="center"> {doctor.notes}</span></Link>
                                                </React.Fragment>
                                              ) : (
                                                <button className="btn btn-primary mr-1 center" onClick={() => this.handleShowModalAddNotes(moment(row.original.date).format("YYYY-MM-DD"), doctor.id, hospital.id, master_key, cycle_id)}>
                                                  add 
                                                </button>
                                              )
                                            }
                                          </div>
    
                                          {
                                            doctor.items.length - 1 != i && (<hr className="m-0" />)
                                          }
                                        </React.Fragment>
                                      )
                                    })
                                  }
                                </div>
    
                                {
                                  Object.keys(row.original.items).length - 1 !=
                                  itemIndex && <hr className="m-0" />
                                }
                              </React.Fragment>
                            )
                          })}
                        </React.Fragment>
                      )
                    })
                ) : ("")
                }
              </React.Fragment>
            )
          },

        {
            Header: 'Patients',
            accessor: 'patients',
            width: this.getColumnWidth('patients', 200),
            id: "patients",
            Cell: row => <React.Fragment>
                {
                    row.original.items.length != 0 ? (
                        Object.keys(row.original.items).sort().map((master_key, itemIndex) => {
                            return <React.Fragment key={itemIndex}>
                                {
                                    row.original.items[master_key].map((doctor, i) => {
                                        return <React.Fragment key={i}>
                                           <div>
                                           {
                                                doctor.items.map((hospital, i) => {
                                                    // console.log('hospital.items', hospital.items)
                                                    return <React.Fragment key={i}>
                                                        <div style={{height: `${this.getHospitalHeight(hospital)}px`}} className="cell-text text-center">
                                                        {
                                                            <button className="btn btn-primary mr-1 center" onClick={() => this.handleShowPatientsModal(moment(row.original.date).format('YYYY-MM-DD'), doctor.id, hospital.id, master_key)}>Show Patients</button>
                                                        }
                                                    </div>

                                                    {(doctor.items.length -1 != i) && <hr className="m-0" />}

                                                    </React.Fragment>
                                                })
                                            }
                                           </div>

                                           {(Object.keys(row.original.items).length -1 != itemIndex) && <hr className="m-0" />}

                                        </React.Fragment>

                                    })
                                }
                            </React.Fragment>
                        })
                    ) : ( <p className='text-center center'>No Booking Cycle</p> )                            
                        
                }
            </React.Fragment>
        }
    ]

        return (
            <div className="container-fluid">
                <Container>
                    <FormGroup>
                        <Row className="center">
                            {/* <Col sm={1}><FormLabel>Patients: </FormLabel></Col>   
                            <Col sm={2}>
                                <FormControl
                                    as="select"
                                    type="text"
                                     name="patient_id"
                                    onChange={this.handlePatient}
                                    className="center"
                                >
                                    <option value="-Select-">-Select-</option>
                                    {this.state.patients.map(patient => ( 
                                        <option value={patient.id}> {patient.name} </option>
                                    ))}
                                    
                                </FormControl>
                            </Col> */}
                            <Col sm={12} md={4} lg={4}>
                                <FormLabel>Doctors: </FormLabel>
                                <FormControl
                                    as="select"
                                    type="text"
                                    name="doctor_id"
                                    id="doctor_id"
                                    onChange={this.handleDoctor}
                                    className="center"
                                >
                                    <option value="-Select-">-Select-</option>
                                    {this.state.doctors.map((doctor, i) => (
                                        <option key={i} value={doctor.id}> {doctor.name} </option>
                                    ))}
                                </FormControl>
                            </Col>
                            <Col sm={12} md={4} lg={4}>
                                <FormLabel className="mt-2 mt-lg-0">Hospitals: </FormLabel>
                                <FormControl
                                    as="select"
                                    type="text"
                                    name="hospital_id"
                                    id="hospital_id"
                                    className="center"
                                    onChange={this.handleHospital}
                                >
                                    <option value="-Select-">Select All</option>
                                    {this.state.hospitals.map((hospital, i) => (
                                        <option key={i} value={hospital.id}> {hospital.name} </option>
                                    ))}
                                </FormControl>
                            </Col>

                            <Col sm={12} md={4} lg={4}>
                                <FormLabel>Slots: </FormLabel>
                                <FormControl
                                    as="select"
                                    name="slot"
                                    id="slot"
                                    className="center"
                                    onChange={this.handleSlotForSearch}
                                >
                                    <option value="-Select-">-Select-</option>
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                </FormControl>
                            </Col>
                        </Row>
                        </FormGroup>
                        <FormGroup>
                        <Row className="center">
                            <Col sm={12} md={4} lg={4}>
                                <FormLabel> Start Date: </FormLabel>
                                <FormControl
                                    type="date"
                                    className="center"
                                    name="start_date"
                                    value={this.state.start_date}
                                    onChange={this.handleClickStartDate}
                                />
                            </Col>
                            <Col sm={12} md={4} lg={4}>
                                <FormLabel className="mt-2 mt-lg-0"> End Date: </FormLabel>
                                <FormControl
                                    type="date"
                                    className="center"
                                    name="end_date"
                                    value={this.state.end_date}
                                    onChange={this.handleClickEndDate}
                                />
                            </Col>
                            <Col sm={12} md={4} lg={2} className="align-self-end">
                                <button type="button" className="btn btn-primary btn-block center" onClick={this.handleApplyFilter}>Search</button>
                            </Col>
                        </Row>
                    </FormGroup>                    

                </Container>
                <div className="card shadow mb-4">
                    <div className="card-header py-3">
                        <Row>
                            <Col><h6 className="m-0 font-weight-bold text-primary">List Summary</h6></Col>
                            <Col sm={3} style={{ textAlign: "right" }}>
                                {/* <FormControl
                                    as="select"
                                    type="text"
                                    name="calendarData"
                                    id="calendarData"
                                    onChange={this.handleCalendarData}
                                    className="center"
                                >
                                <option value="Choose...">Choose...</option>
                                    <option value="booking-cycle">Booking Cycle</option>
                                    <option value="booking-cycle-off-list">Booking Cycle Off List</option>
                                </FormControl> */}
                                {/* <button
                                type="submit"
                                className="btn btn-primary center"
                                onClick={this.handleGoBackTable}
                                style={
                                    this.state.booking_date_collection_length > 0
                                    ? { display: "block" }
                                    : { display: "none" }
                                }
                                >
                                <i className="fas fa-arrow-left" /> Go Back
                                </button> */}
                            </Col>
                        </Row>
                    </div>
                    <div className="card-body">

                        {/* Modal for showing notes in Calendar*/}
                        <Modal show={this.state.showCalendarNotes}>
                        <Modal.Header onClick={this.hideModalAddNotes} closeButton>
                            <Modal.Title>
                                <h5 className="m-0 font-weight-bold text-danger">Notes:</h5>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="center" style={{ display: this.state.viewCalendarNotes}}>{this.state.current_calendar_notes}</p>
                            <FormControl
                                as="textarea"
                                name="current_calendar_notes"
                                className="center"
                                rows={8}
                                style={{ display: this.state.showEditCalendarNotes}}
                                value={this.state.current_calendar_notes}
                                onChange={this.handleCurrentCalendarNotes}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-primary center" style={{ display: this.state.updateButtonDisplay}} onClick={this.handleShowNotesUpdateForm}>
                                Update
                            </button>
                            <button className="btn btn-primary center" style={{ display: this.state.saveButtonDisplay}} onClick={this.handleSaveEdittedNotes}>
                                Save
                            </button>
                            <button className="btn btn-danger center" onClick={this.hideModalAddNotes}>
                                Cancel
                            </button>
                        </Modal.Footer>
                        </Modal>

                        {/* Modal for Adding notes in Calendar*/}
                        <Modal show={this.state.showModalAddNotes}>
                            <Modal.Header onClick={this.hideModalAddNotes} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Add Notes:</h5></Modal.Title>
                            </Modal.Header>
                        <Modal.Body>
                            {/* <FormLabel className="center">Notes: </FormLabel> */}
                            <FormControl
                                as="textarea"
                                name="calendar_notes"
                                className="center"
                                value={this.state.calendar_notes}
                                onChange={this.handleCalendarNotes}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn btn-danger center" onClick={this.hideModalAddNotes}>
                                Cancel
                            </button>
                            <button className="btn btn-primary center" onClick={this.handleAddNotes}>
                                Save
                            </button>
                        </Modal.Footer>
                        </Modal>

                        {/* Modal for Delete Booking  */}
                        <Modal show={this.state.showDeleteBookingModal} backdrop="static">
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h6 className="center" style={{textAlign: 'center'}}>Are you sure you want to DELETE this patient?</h6>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModal}>
                                    Cancel
                                </button>
                                <button 
                                    className="btn btn-danger center" 
                                    value={this.state.currentBookingId} 
                                    onClick={this.deletePatientInBookingListHandler}
                                >Delete
                                </button>
                            </Modal.Footer>
                        </Modal>

                        <Modal show={this.state.showMoveBooking} size="lg">
                            <Modal.Header onClick={this.hideAddBooking} closeButton>
                                <Modal.Title>
                                <h5 className="m-0 font-weight-bold text-danger">
                                    Move Patient to Other Booking List:
                                </h5>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* Start of Form for Moving Patient Booking */}
                                {
                                    this.state.isLoadingForMovingPatient ? (
                                        <Loader
                                            type="TailSpin"
                                            color="#2850C2"
                                            height={80}
                                            width={80}
                                        />
                                    ) : (
                                        <Container>
                                            <FormGroup>
                                                <Row className="center">
                                                <Col lg={5}>
                                                    <FormLabel>
                                                    Patient's Name:
                                                    <span style={{ color: "red" }}>*</span>
                                                    </FormLabel>
                                                </Col>
                                                <Col lg={7}>
                                                    <FormControl
                                                        type="text"
                                                        name="move_booking_patient"
                                                        className="center"
                                                        value={this.state.moveBookingPatientName}
                                                        disabled="true"
                                                    />
                                                </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={5}>
                                                        <FormLabel>
                                                            Doctor: <span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                    </Col>
                                                    <Col lg={7}>
                                                    <FormControl
                                                        as="select"
                                                        type="text"
                                                        name="doctor"
                                                        onChange={this.handleDoctorId}
                                                        className="center"
                                                        value={this.state.currentDoctorId}
                                                    >
                                                        <option value="-Select-">-Select-</option>
                                                        {this.state.doctorsWithBookingCycles.map(doctor => ( 
                                                            <option value={doctor.doctorId}> {doctor.name} </option>
                                                        ))}
                                                        
                                                    </FormControl>                             
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                <Col lg={5}>
                                                    <FormLabel>
                                                    Hospital: <span style={{ color: "red" }}>*</span>
                                                    </FormLabel>
                                                </Col>
                                                <Col lg={7}>
                                                    {
                                                    this.state.multipleDoctor === 0 ? 
                                                        (<FormControl
                                                            type="text"
                                                            name="hospital_id"
                                                            id="hospital_id"
                                                            className="center"
                                                            value={this.state.currentHospital}   
                                                            disabled="true"                                        
                                                        />) : 
                                                        (
                                                        <FormControl
                                                            as = "select"
                                                            type="text"
                                                            name="move_booking_hospital"
                                                            className="center"
                                                            onChange={this.handleHospitalId}
                                                        >   
                                                            <option value="Select All">Select All</option>
                                                            {this.state.hospitalsForMoveBooking.map(obj => (
                                                                ( obj.hospitalStatus ? <option value={obj.hospitalId}> {obj.hospitalName} </option> : '' )                                                                                  
                                                            ))} 
                                                        </FormControl>
                                                        )
                                                    }
                                                </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                    <Col lg={5}>
                                                        <FormLabel>
                                                            Date: <span style={{ color: "red" }}>*</span>
                                                        </FormLabel>
                                                    </Col>
                                                    <Col lg={7}>    
                                                        {
                                                        this.state.isLoadingCalendar? (
                                                            <Loader
                                                                type="TailSpin"
                                                                color="#2850C2"
                                                                height={80}
                                                                width={80}
                                                            />
                                                        ) : (
                                                            <Calendar
                                                                onChange={this.onChange}
                                                                onClickDay={this.handleCalendar}
                                                                style={{width: '100%'}}
                                                                tileClassName={({ date }) => { 
                                                                    if(this.state.booking_dates.find(x => x == moment(date).format("YYYY-MM-DD"))){
                                                                        return 'react-calendar__tile--active'
                                                                    }                                   
                                                                }}

                                                                tileDisabled={({ date, i }) => {
                                                                    let isBetween = true;
                                                                    const dateFormatted = new Date(moment(date).format('YYYY-MM-DD')).getTime();
                                                                    const timeStamp = 24*60*60*1000;

                                                                    // /* For when choosing patients*/
                                                                    // if(this.state.booking_dates.find(x => x == moment(date).format("YYYY-MM-DD"))){
                                                                    //     return isBetween = false;
                                                                    // }  
                                                                    
                                                                    /* For an offlist */
                                                                    if(this.state.offListDate.length > 0){
                                                                        this.state.offListDate.forEach(element => {
                                                                            const offListDate = new Date(element).getTime();
                                                                            if(offListDate === dateFormatted){
                                                                                isBetween = false;                                                        
                                                                            } 
                                                                        });
                                                                    } 

                                                                    // if(this.state.offListDate !== "" || this.state.offListDate !== undefined){
                                                                    //     const offListDate = new Date(this.state.offListDate).getTime();
                                                                    //     if(offListDate === dateFormatted){
                                                                    //         isBetween = false;                                                        
                                                                    //     }                                                    
                                                                    // } 

                                                                    // let dayAddNumber_ctr = 0;
                                                                    
                                                                    // this.state.dayAddNumberCollection.forEach(element => {
                                                                    //     if(this.state.start_dates[dayAddNumber_ctr]){
                                                                    //         this.state.start_dates[dayAddNumber_ctr].forEach(element_2 => {
                                                                    //             let startDate = new Date(element_2).getTime();
                                                                    //             let officialdate = startDate + element*timeStamp;
                                                                    //             if(dateFormatted === officialdate) {
                                                                    //                 isBetween = false;
                                                                    //             } 
                                                                    //         });      
                                                                    //     }
                                                                    //     dayAddNumber_ctr++;     
                                                                    // });
                                                                    // return isBetween;

                                                                    /* For multiple booking cycle */
                                                                    if(this.state.start_dates.length > 0){

                                                                        for (let index = 0; index < this.state.start_dates.length; index++) {
                                                                            this.state.start_dates[index].forEach(element_2 => {
                                                                                let startDate = new Date(element_2).getTime();
                                                                                let officialdate = startDate;
                                                                                if(dateFormatted === officialdate) {
                                                                                    isBetween = false;
                                                                                } 
                                                                            });   
                                                                            
                                                                        }
                                                                           
                                                                    }
                                                                    return isBetween;

                                                                }}
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                <Col lg={5}>
                                                    <FormLabel>
                                                    Slot: <span style={{ color: "red" }}>*</span>
                                                    </FormLabel>
                                                </Col>
                                                <Col lg={7}>
                                                    {
                                                        this.state.slotOptions.length > 1 ? (
                                                            <FormControl
                                                                as="select"
                                                                name="moveBookingSlot"
                                                                className="center"
                                                                value={this.state.slot}
                                                                onChange={this.handleSlot}
                                                            >
                                                                
                                                                <option value="-Select-">-Select-</option>
                                                                {this.state.slotOptions.map((obj, i) => (
                                                                    <option key={i} value={obj}>
                                                                        {obj}
                                                                    </option>
                                                                ))}
                                                            </FormControl> 
                                                        ) : (
                                                                <FormControl
                                                                type="text"
                                                                name="moveBookingSlot"
                                                                className="center"
                                                                value={this.state.slotOptions[0] ? this.state.slotOptions[0] : this.state.slot}
                                                                onChange={this.handleChange}
                                                                disabled="true"
                                                            /> 
                                                        )
                                                    }
                                                </Col>
                                                </Row>
                                            </FormGroup>
                                            <FormGroup>
                                                <Row className="center">
                                                <Col lg={5}>
                                                    <FormLabel>
                                                    Procedure:
                                                    <span style={{ color: "red" }}>*</span>
                                                    </FormLabel>
                                                </Col>
                                                <Col lg={7}>
                                                    <Multiselect
                                                    options={this.state.moveBookingProcedures}
                                                    // selectedValues={this.state.moveBookingProcedures} 
                                                    onSelect={this.moveBookingOnSelectTreatment}
                                                    onRemove={this.moveBookingOnRemoveTreatment}
                                                    displayValue="name"
                                                    className="center"
                                                    style={mutiselectStyle}
                                                    avoidHighlightFirstOption="true"
                                                    />
                                                </Col>
                                                </Row>
                                            </FormGroup>
                                        </Container>
                                    )
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    className="btn btn-danger center"
                                    onClick={this.hideAddBooking}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary center"
                                    onClick={this.handleMovePatientToOtherBooking}
                                >
                                Move
                                </button>
                            </Modal.Footer>
                        </Modal>
                        
                        {/* Modal for Show Patients List */}
                        <Modal show={this.state.showPatientsModal} backdrop="static" className="center" size="lg">
                            <Modal.Header onClick={this.hideModalShowPatients} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Patients List:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            {
                                this.state.isLoadingGetSummaryPatients ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#2850C2"
                                        height={80}
                                        width={80}
                                    />
                                ) : (
                                    this.state.patient_result.length != 0 ? (
                                        <Table size="sm" className="center" striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th style={{ textAlign: 'center' }}> Status </th>
                                                <th style={{ textAlign: 'center' }}> Treatment </th>
                                                <th style={{ textAlign: 'center' }}> Notes </th>
                                                <th> Actions </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                 this.state.patient_result.map((obj, i) => {
                                                    // console.log("Patients List", obj)
                                                    return <tr key={i}>
                                                                <td>
                                                                    <Link onClick={(e) => this.goToEditPatientBooking(obj.patients.id, obj.procedure_id, obj.patients.title + '. ' + obj.patients.first_name + " " + obj.patients.last_name)}>{obj.patients.title + '. ' + obj.patients.first_name + ' ' + obj.patients.last_name}</Link>
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    {obj.treated ? 'Treated' : (obj.status ? 'Booked' : 'Cancelled')}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    {obj.procedure}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    {obj.notes ? obj.notes : "No notes available"}
                                                                </td>
                                                                <td style={{ textAlign: 'center' }}>
                                                                    {/* <button
                                                                        className="btn btn-primary center"
                                                                        onClick={(e) => this.goToEditPatientBooking(obj.patients.id)}
                                                                    >Update
                                                                        </button> */}
                                                                    <i className="fas fa-edit" title="Update" onClick={(e) => this.goToEditPatientBooking(obj.patients.id, obj.procedure_id, obj.patients.title + ". " + obj.patients.first_name + " " + obj.patients.last_name)} />
                                                                    <i className="fas fa-exchange-alt ml-2" title="Move to another Booking List" onClick={(e) => this.goToMovePatient(obj.ID, obj.patients.id, obj.patients.title + ". " + obj.patients.first_name + " " + obj.patients.last_name)}/> 
                                                                    <i className="far fa-trash-alt ml-2" title="Delete" onClick={(e) => this.deletePatientInBookingList(obj.ID)}/>
                                                                </td>
                                                            </tr>
                                                })
                                            }
    
                                        </tbody>
                                    </Table>
                                    ) : (
                                        <p style={{textAlign: 'center'}} className="center">No patients</p>
                                    )
                                )                                
                            }
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-danger center" onClick={this.hideModalShowPatients}>
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-primary center"
                                    onClick={() => this.handleShowBooking(this.state.add_staff_date)}
                                >Add Patient
                                </button>
                            </Modal.Footer>
                        </Modal>

                         {/* Modal for New Patient or Old Patient */}
                         <Modal show={this.state.show}>
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="center" style={{ textAlign: "center" }}>
                                <p className="center" style={{ textAlign: 'center' }}>Please select the type of Patient. </p>
                                <button className="btn btn-primary center" onClick={this.handleNewPatient}> New Patient </button>
                                <button className="btn btn-primary center" style={{ marginLeft: '10px' }} onClick={this.handleOldPatient}> Existing Patient  </button>
                            </Modal.Body>
                        </Modal>

                        {/* Modal for Add Patient  */}
                        <Modal
                            show={this.state.showAddPatientModal}
                            backdrop="static"
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header onClick={this.hideAddPatientModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-primary">Add Patient:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <Row className="center">
                                        <Col lg={2}><FormLabel>Title: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                        <Col sm={3}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="title"
                                                className="center"
                                                value={this.state.title}
                                                onChange={this.handleChange}
                                            >
                                                <option value="">-Select-</option>
                                                <option value="Dr">Dr</option>
                                                {/* <option value="Miss">Miss</option> */}
                                                <option value="Mrs">Mrs</option>
                                                <option value="Ms">Ms</option>
                                                <option value="Mr">Mr</option>
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <hr />
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={2}>First Name: <span style={{ color: 'red' }}>*</span></Col>
                                        <Col sm={2}>
                                            <FormControl
                                                type="text"
                                                name="first_name"
                                                className="center"
                                                value={this.state.first_name}
                                                onChange={this.handleChange}
                                                required
                                            />
                                        </Col>
                                        <Col sm={2}><FormLabel>Middle Name: </FormLabel></Col>
                                        <Col sm={2}>
                                            <FormControl
                                                type="text"
                                                name="second_name"
                                                className="center"
                                                value={this.state.second_name}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col sm={2}><FormLabel>Last Name: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                        <Col sm={2}>
                                            <FormControl
                                                type="text"
                                                name="last_name"
                                                className="center"
                                                value={this.state.last_name}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <hr />
                                <FormGroup>
                                    <Row className="center">
                                        <Col lg={2}><FormLabel>Date of Birth: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                        <Col sm={4}>
                                            <FormControl
                                                type="date"
                                                className="center"
                                                name="date_of_birth"
                                                value={this.state.date_of_birth}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <hr />
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={2}><FormLabel>Address 1: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                        <Col sm={4}>
                                            <FormControl
                                                type="text"
                                                name="address_1"
                                                className="center"
                                                value={this.state.address_1}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col sm={2}><FormLabel>Address 2: </FormLabel></Col>
                                        <Col sm={4}>
                                            <FormControl
                                                type="text"
                                                name="address_2"
                                                className="center"
                                                value={this.state.address_2}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={2}><FormLabel>Suburb: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                        <Col sm={2}>
                                            <FormControl
                                                type="text"
                                                name="suburb"
                                                className="center"
                                                value={this.state.suburb}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col sm={2}><FormLabel>State: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                        <Col sm={2}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="state"
                                                className="center"
                                                value={this.state.state}
                                                onChange={this.handleChange}
                                            >
                                                <option value="">-Select-</option>
                                                <option value="ACT">ACT</option>
                                                <option value="NSW">NSW</option>
                                                <option value="VIC">VIC</option>
                                                <option value="TAS">TAS</option>
                                                <option value="QLD">QLD</option>
                                                <option value="SA">SA</option>
                                                <option value="WA">WA</option>
                                                <option value="NT">NT</option>
                                            </FormControl>
                                        </Col>
                                        <Col sm={2}><FormLabel>Post Code: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                        <Col sm={2}>
                                            <FormControl
                                                type="text"
                                                name="post_code"
                                                className="center"
                                                value={this.state.post_code}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <hr />
                                <FormGroup className="center">
                                    <Row className="center">
                                        <Col sm={2}><FormLabel>Doctor: <span style={{color: 'red'}}>*</span></FormLabel></Col>
                                        <Col sm={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="doctorId"
                                                className="center"
                                                // onChange={this.handleChange}
                                                value={this.state.doctorId}
                                                disabled={true}
                                            >
                                                <option value="-Select-">-Select-</option>
                                                {this.state.doctorsWithBookingCycles.map((doctor, i) => 
                                                    <option key={i} value={doctor.id}>{doctor.name}</option>
                                                )}
                                            </FormControl>
                                        </Col>
                                        <Col sm={2}><FormLabel>Treatment/Procedure: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                        <Col sm={4}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="procedure_id"
                                                className="center"
                                                onChange={this.handleChange}
                                            >
                                                <option value="">-Select-</option>
                                                {this.state.procedures.map((procedure, i) =>
                                                    <option key={i} value={procedure.id}>{procedure.name}</option>
                                                )}
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideAddPatientModal}>
                                    Cancel
                                </button >
                                <button className="btn btn-primary center" onClick={this.handleAddPatient}>
                                    Add
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Patient Successfully Added  */}
                        <Modal show={this.state.showSuccessForAddPatient}>
                            <Modal.Header onClick={this.hideModalSuccess} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation!</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{ textAlign: "center" }}>{this.state.successMessageAddPatient}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModalSuccessAddPatient} >OK</button>
                            </Modal.Footer>
                        </Modal>
                        

                        {/* Modal for Update of Booking Information in Patient List Modal  */}
                        <Modal show={this.state.showUpdateTreatment} backdrop="static" >
                            <Modal.Header onClick={this.hideUpdateTreatment} closeButton>
                                <Modal.Title>
                                    <h5 className="m-0 font-weight-bold text-danger">Update Booking Information</h5>
                                    <span className="center">{this.state.editBookingPatientName}</span>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={4}><FormLabel>Treatment/Procedure: </FormLabel></Col>
                                        <Col sm={6}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="procedure"
                                                className="center"
                                                value={this.state.procedure}
                                                onChange={this.handleChange}
                                            >
                                                <option value="-Select-">-Select-</option>
                                                {this.state.procedures.map((obj, i) => (
                                                    <option key={i} value={obj.id}> {obj.name} </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={4}><FormLabel>UR Number: </FormLabel></Col>
                                        <Col sm={6}>
                                            <FormControl
                                                type="number"
                                                name="ur_number"
                                                className="center"
                                                value={this.state.ur_number}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col sm={4}><FormLabel>Probe: </FormLabel></Col>
                                        <Col sm={6}>
                                            <FormControl
                                                as="select"
                                                type="text"
                                                name="probe"
                                                className="center"
                                                value={this.state.probe}
                                                onChange={this.handleChange}
                                            >
                                                <option value="-Select-">-Select-</option>
                                                {this.state.probes.map(probe => (
                                                    <option key={probe.id} value={probe.name}> {probe.name} </option>
                                                ))}
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col lg={4}><FormLabel>Notes: </FormLabel></Col>
                                        <Col sm={6}>
                                            <FormControl
                                                as="textarea"
                                                name="notes"
                                                className="center"
                                                value={this.state.notes}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup>
                                    <Row className="center">
                                        <Col lg={6} style={{ textAlign: 'left' }}>
                                            <input type="checkbox" disabled={this.state.chkboxTreated ? true : this.state.disableFunctionCancelled} defaultChecked={this.state.chkboxCancelled} checked={this.state.chkboxCancelled} onClick={this.handleCancelled} /> <span className="center" style={{ color: 'red' }}>Cancelled patient</span>
                                        </Col>
                                        <Col lg={6} style={{ textAlign: 'right' }}>
                                            {/* <input
                                                type="checkbox"
                                                disabled={this.state.chkboxCancelled ? true : this.state.disableFunctionTreated}
                                                defaultChecked={this.state.chkboxTreated}
                                                checked={this.state.chkboxTreated}
                                                onClick={this.handleTreated}
                                            />
                                            <span className="center" style={{ color: 'red' }}> Mark as treated</span> */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>{this.state.error && (<p style={{ color: "#FF0000", textAlign: 'center' }} className="center m-2">No treatment selected.</p>)}</Col>
                                    </Row>
                                    <Row>
                                        <Col>{this.state.errorMessage && (<p style={{ color: "#FF0000", textAlign: 'center' }} className="center m-2">{this.state.errorMessage}</p>)}</Col>          
                                    </Row>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.handleUpdateBookingTreatment} >Update</button>
                            </Modal.Footer>
                        </Modal>

                        {/* Add Booking */}
                        <Modal
                            show={this.state.showAddBooking}
                            size='lg'
                        >
                            <Modal.Header onClick={this.hideAddBooking} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Add Booking: </h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {/* Start of Form for Booking */}
                                <Container>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col sm={3}><FormLabel>Doctor: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                            <Col sm={6}>
                                                <FormControl
                                                    type="text"
                                                    name="doctor"
                                                    className="center"
                                                    value={this.state.doctorName}
                                                    disabled="true"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col sm={3}><FormLabel>Hospital: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                            <Col sm={6}>
                                                <FormControl
                                                    type="text"
                                                    name="hospital_id"
                                                    className="center"
                                                    value={this.state.hospitalName}
                                                    disabled="true"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col sm={3}><FormLabel>Treatment/Procedure: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                            <Col sm={6}>
                                                <Multiselect
                                                    options={this.state.finalProcedure}
                                                    selectedValues={this.state.selectedProcedure}
                                                    onSelect={this.onSelectTreatment}
                                                    onRemove={this.onRemoveTreatment}
                                                    displayValue="name"
                                                    className="center"
                                                    style={mutiselectStyle}
                                                    avoidHighlightFirstOption="true"
                                                />
                                                {/* <FormControl
                                                        type="text"
                                                        name="procedure"
                                                        className="center"
                                                        value={this.state.procedureName}   
                                                        disabled="true"                                         
                                                    />   */}
                                            </Col>

                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={3}><FormLabel>Patient's Name: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                            <Col sm={6}>
                                                <Multiselect
                                                    options={this.state.patients}
                                                    selectedValues={this.state.selectedPatients}
                                                    onSelect={this.onSelect}
                                                    onRemove={this.onRemove}
                                                    displayValue="name"
                                                    className="center"
                                                    style={mutiselectStyle}
                                                    avoidHighlightFirstOption="true"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={3}><FormLabel>Slot: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                            <Col sm={6}>
                                                <FormControl
                                                    type="text"
                                                    name="slot"
                                                    className="center"
                                                    value={this.state.slot}
                                                    disabled="true"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={3}><FormLabel>Date: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                            <Col sm={6}>
                                                <FormControl
                                                    type="text"
                                                    name="date"
                                                    className="center"
                                                    value={moment(this.state.booking_date).format("MMMM D YYYY")}
                                                    disabled="true"
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                    <FormGroup>
                                        <Row className="center">
                                            <Col lg={3}><FormLabel>Notes: </FormLabel></Col>
                                            <Col sm={6}>
                                                <FormControl
                                                    as="textarea"
                                                    name="notes"
                                                    className="center"
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-danger center" onClick={this.hideAddBooking}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary center" onClick={this.handleFormSubmit}>
                                    Save
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Patient Successfully Added  */}
                        <Modal show={this.state.showSuccessModal}>
                            <Modal.Header onClick={this.hideModalSuccess} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation!</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{ textAlign: "center" }}>{this.state.successMessage}</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary center" onClick={this.hideModalSuccess}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR  */}
                        <Modal show={this.state.showError}>
                            <Modal.Header onClick={this.hideModalError} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{ textAlign: "center" }}>{this.state.errorMessage}</p>
                                {
                                    this.state.errorData && Object.keys(this.state.errorData).map(key => <p style={{ textAlign: "center" }}>{this.state.errorData[key]}</p>)
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.hideModalError}>
                                    OK
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for ERROR for Existing patient  */}
                        <Modal show={this.state.showErrorForExistingPatient}>
                            <Modal.Header onClick={this.hideErrorForExistingPatient} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Error:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                            {
                                this.state.patientErrorData && <p style={{textAlign: "center"}}>{this.state.patientErrorData}. Do you still want to continue? </p>
                            }                                        
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-primary" onClick={this.handleContinueCreatingPatient}>
                                    Yes
                                </button>
                                <button className="btn btn-danger" onClick={this.hideErrorForExistingPatient}>
                                    Cancel
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Adding Staff*/}
                        <Modal show={this.state.showAddStaffModal}>
                            <Modal.Header onClick={this.hideAddStaffModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">{this.state.staff_heading}:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row className="center">
                                    <Col lg={2}><FormLabel>Staff: <span style={{ color: 'red' }}>*</span></FormLabel></Col>
                                    <Col sm={6}>
                                        <FormControl
                                            as="select"
                                            type="text"
                                            name="staff"
                                            onChange={this.handleStaff}
                                            value={this.state.staff_id}
                                            className="center"
                                        >
                                            <option value="-Select-">-Select-</option>
                                            {this.state.staffs.map((staff, i) => (
                                                <option key={i} value={staff.staffId}> {staff.staff_name} </option>
                                            ))}
                                        </FormControl>
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-danger center" onClick={this.hideAddStaffModal}>
                                    Cancel
                                </button>
                                <button className="btn btn-primary center" onClick={this.handleSaveStaff}>
                                    {this.state.staff_button}
                                </button>
                            </Modal.Footer>
                        </Modal>

                        {/* Modal for Delete Booking  */}
                        <Modal show={this.state.showDeleteModal} backdrop="static" className="center">
                            <Modal.Header onClick={this.hideModal} closeButton>
                                <Modal.Title><h5 className="m-0 font-weight-bold text-danger">Confirmation:</h5></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <p className="center" style={{textAlign: 'center'}}>Are you sure you want to delete this staff?</p>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="btn btn-secondary center" onClick={this.hideModal}>
                                    Cancel
                                </button>
                                <button
                                    className="btn btn-danger center"
                                    onClick={this.deleteHandler}
                                >Delete
                                </button>
                            </Modal.Footer>
                        </Modal>
                        {
                             this.state.isLoading ? (
                                <Loader
                                    type="TailSpin"
                                    color="#2850C2"
                                    height={80}
                                    width={80}
                                />
                            ): ( 
                                <ReactTable
                                    data={this.state.bookingsByDate} // The data prop should be immutable and only change when you want to update the table
                                    columns={columns}
                                    style={{overflow:'wrap'}}
                                    PaginationComponent={Pagination}
                                    className="center"
                                    minRows={0}
                                    getTrProps={this.getTrProps}  
                                    onResizedChange={this.getResizeValues}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default SecretaryPage;